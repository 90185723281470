.jfy-switch .react-switch-bg,
.jfy-switch .react-switch-handle {
  border-radius: unset !important;
}

.jfy-switch .react-switch-bg {
  height: 25px !important;
  width: 54px !important;
}

.jfy-switch .react-switch-bg svg {
  display: none;
}

.react-switch-handle {
  height: 23px !important;
  width: 23px !important;
}

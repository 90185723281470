.container {
  padding-bottom: 40px;
}

.containerHeader {
  height: 60px;
  display: flex;
  justify-content: center;
}

.container .containerHeader > div:first-of-type {
  width: 95%;
  max-width: 950px;
  justify-content: flex-start;
  align-items: center;
}

.header {
  width: 100%;
}

.header h1 {
  font-size: var(--font-size-page-header);
}

.header h1 span {
  text-transform: uppercase;
}

.back {
  display: flex;
  align-items: center;
  margin: 0 auto 15px 0;
}

.back svg {
  height: 10px;
  width: 10px;
}

.back svg > path {
  fill: var(--color-international-orange-aerospace);
}

.back:hover svg > path {
  fill: var(--color-copper-red);
}

.container .blocks {
  width: 95%;
  max-width: 950px;
}

.block {
  margin-bottom: 20px;
}

.block > div a {
  font-size: var(--font-size-h3);
  margin: 5px 0;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--color-blue-crayola);
  text-decoration: unset;
}

.block > div {
  background-color: var(--color-white);
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-title);
}

.block input {
  height: 35px;
}

.block h2 {
  margin-top: 15px;
}

.closeHeader {
  margin-bottom: 0;
}

.name {
  margin-right: 40px;
}

.name h2 {
  margin-top: 0;
}

.name .input {
  width: 300px;
}

.name p {
  font-size: var(--font-size-h2);
}

.quantities {
  display: flex;
  flex-direction: row;
}

.quantities p {
  font-size: var(--font-size-h2);
}

.quantities p span {
  font-weight: bold;
}

.leftPadded {
  margin-left: 40px;
}

.quantity .input {
  width: 60px;
}

.flexContainer {
  display: flex;
  flex-direction: row;
}

.flexContainer > div:not(:first-child) {
  margin-left: 40px;
}

.sheet .input {
  width: 40%;
}

.productsHeader {
  display: flex;
}

.productsHeader > h3:not(:first-child) {
  margin-left: 60px;
}

.product {
  display: flex;
  align-items: flex-start;
}

.product .input {
  margin-left: 20px;
  width: 20%;
}

.date h2 {
  margin-top: 0;
}

.date .input {
  width: 190px;
}

.button {
  margin: 30px auto 0 auto;
  width: 40%;
}

.buttonAdd {
  width: 35px;
  height: 35px;
  margin-left: 20px;
}

.hidden {
  display: none;
}

@media (max-width: 600px) {
  .flexContainer {
    flex-direction: column;
  }

  .flexContainer > div:not(:first-child) {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .containerHeader {
    height: auto;
  }

  .container .blocks {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .container .containerHeader > div:first-of-type {
    width: 100%;
    justify-content: center;
  }
}

.filter-container {
  font-family: var(--font-family-title);
  font-size: 12px;
}

.filter-container.single-filter {
  max-height: 35px;
}

.filter-container:focus {
  border: unset;
  font-size: 12px;
}

.filter-container .filter__control {
  border-radius: unset;
  border: 1px solid var(--color-gray-web);
  box-shadow: unset;
  min-height: 35px;
  font-size: 12px;
}

.filter-container .filter__control:hover {
  border-color: var(--color-gray-web);
}

.filter-container .filter__indicator-separator {
  display: none;
}

.filter-container .filter__value-container,
.filter-container .filter__menu {
  font-weight: 600;
}

.filter-container .filter__value-container {
  padding: 2px 0 2px 8px;
}

.filter-container .filter__indicator {
  padding: 0 4px;
}

.filter-container .filter__menu {
  border-radius: unset;
}

.filter-container .filter__option {
  background-color: var(--color-white);
}

.filter-container .filter__option:hover {
  background-color: var(--color-light-salmon-lighter);
}

.filter-container .filter__option--is-filtered,
.filter-container .filter__option--is-filtered:hover {
  background-color: var(--color-light-salmon);
}

@media (max-width: 1024px) {
  .filter-container {
    font-size: 11px;
  }

  .filter-container:focus {
    font-size: 11px;
  }

  .filter-container .filter__control {
    font-size: 11px;
  }
}

/* BurgerMenu third-party Component classes */
/* docs: https://github.com/negomi/react-burger-menu#styling */

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 22px;
  height: 13px;
  left: 3.5%;
  top: 25px;
}

@media (min-width: 768px) {
  .bm-burger-button {
    top: 15px;
    left: 3%;
  }
}
@media (min-width: 320px) and (max-width: 768px){
  .bm-burger-button {
    top: 15px;
    left: 15px;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 17px;
  width: 17px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffffff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 280px !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 0 !important;
  font-size: 1.15em;
  border-right: solid 1px var(--color-quick-silver);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: var(--color-black);
  font-family: var(--font-family-title);
  size: 14px;
  font-weight: 600;
}

/* Individual item */
.bm-item {
  display: inline-block;
  border-bottom: 1px solid #c4c4c4;
}

.bm-item h1 {
  font-size: var(--font-size-page-header);
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0) !important;
  opacity: 0 !important;
  display: none;
}

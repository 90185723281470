.btn {
  display: block;
  font-family: var(--font-family-title);
  text-decoration: none;
  cursor: pointer;
}

.btn.disabled,
.btn:disabled {
  cursor: default;
}

.btn svg {
  margin-right: 5px;
}

.btn span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Variants */

/* Primary */
.primary {
  padding: 11px;
  font-size: var(--font-size-button-primary);
  font-style: normal;
  font-weight: bold;
  color: var(--color-white);
  text-align: center;
  background: var(--color-light-salmon);
  border: unset;
}

.primary.hover,
.primary:hover {
  background-color: var(--color-dark-salmon);
}

.primary.active,
.primary:active {
  background-color: var(--color-copper-crayola);
}

.primary.disabled,
.primary:disabled {
  color: var(--color-isabelline);
  background-color: var(--color-champagne-pink);
}

/* Secondary */
.secondary {
  padding: 9px;
  font-size: var(--font-size-button);
  font-weight: bold;
  color: var(--color-light-salmon);
  text-align: center;
  text-align: center;
  background-color: rgba(255, 255, 255, 0);
  border: 1.5px solid var(--color-light-salmon);
}

.secondary.hover,
.secondary:hover {
  color: var(--color-dark-salmon);
  border-color: var(--color-dark-salmon);
}

.secondary.active,
.secondary:active {
  color: var(--color-copper-crayola);
  border-color: var(--color-copper-crayola);
}

.secondary.disabled,
.secondary:disabled {
  color: var(--color-linen);
  border-color: var(--color-champagne-pink);
}

/* Tertiary */
.tertiary {
  padding: 0;
  font-size: var(--font-size-button);
  font-weight: 600;
  color: var(--color-international-orange-aerospace);
  background: rgba(255, 255, 255, 0);
  border: unset;
}

.tertiary.hover,
.tertiary:hover {
  color: var(--color-copper-red);
}

.tertiary.active,
.tertiary:active {
  color: var(--color-flame);
}

.tertiary.disabled,
.tertiary:disabled {
  color: var(--color-deep-champagne);
}

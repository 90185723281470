.container {
  padding-bottom: 40px;
}

.header {
  width: 100%;
}

.header h1 {
  font-size: var(--font-size-page-header);
}

.header h1 span {
  text-transform: uppercase;
}

.back {
  display: flex;
  align-items: center;
  margin: 0 auto 15px 0;
}

.back svg {
  height: 10px;
  width: 10px;
}

.back svg > path {
  fill: var(--color-international-orange-aerospace);
}

.back:hover svg > path {
  fill: var(--color-copper-red);
}

.container .blocks {
  width: 95%;
  max-width: 950px;
}

.block {
  margin-bottom: 20px;
}

.block > div {
  background-color: var(--color-white);
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-title);
}

.block input {
  height: 35px;
}

.block h2 {
  margin-top: 15px;
}

.active,
.voucher {
  margin: 0 10%;
}

.row {
  display: flex;
}

.row > div {
  width: 30%;
  margin: 0 10%;
}

.voucher {
  width: 30%;
}

.voucher input {
  text-transform: uppercase;
}

.utms > h2,
.products {
  margin: 25px 10% 0;
}

.button {
  margin: 50px auto 0 auto;
  width: 40%;
}

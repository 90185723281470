.select-container {
  font-family: var(--font-family-title);
}

.select-container:focus {
  border: unset;
}

.select-container .select__control {
  border-radius: unset;
  border: 1.5px solid var(--color-light-salmon);
  box-shadow: unset;
}

.select-container .select__control:hover {
  border-color: var(--color-dark-salmon);
}

.select-container .select__indicator-separator {
  display: none;
}

.select-container .select__value-container {
  text-align: center;
}

.select-container .select__value-container,
.select-container .select__menu {
  font-weight: 600;
}

.select-container .select__indicator {
  color: var(--color-light-salmon);
}

.select-container .select__indicator:hover {
  color: var(--color-dark-salmon);
}

.select-container .select__menu {
  border-radius: unset;
}

.select-container .select__option {
  background-color: var(--color-white);
}

.select-container .select__option:hover {
  background-color: var(--color-light-salmon-lighter);
}


.select-container .select__option--is-selected,
.select-container .select__option--is-selected:hover {
  background-color: var(--color-light-salmon);
}

.logoContainer {
  background-color: var(--color-light-salmon);
  height: 195px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  cursor: default;
}

.jfyLogo {
  width: 143px;
  height: 57px;
}

.item {
  padding: 5px 40px;
  background-color: var(--color-white);
  cursor: pointer;
}

.selected {
  color: var(--color-light-salmon) !important;
}

.disabled {
  pointer-events: none;
}

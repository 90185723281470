.input-selection-container {
  font-family: var(--font-family-title);
  cursor: pointer;
}

.input-selection-container.single-filter {
  max-height: 35px;
}

.input-selection-container:focus {
  border: unset;
}

.input-selection-container .input-selection__control {
  border-radius: unset;
  box-shadow: unset;
  min-height: 15px;
  /* height: 20px; */
  max-width: 105px;
  border-radius: 15px;
  cursor: pointer;
  background: #eceaea;
  font-size: 12px;
}

.input-selection-container .input-selection__control:hover {
  border-color: var(--color-gray-web);
}

.input-selection-container .input-selection__indicator-separator {
  display: none;
}

.input-selection-container .input-selection__value-container,
.input-selection-container .input-selection__menu {
  font-weight: 600;
}

.input-selection-container .input-selection__value-container {
  padding: 2px 0 2px 8px;
}

.input-selection-container .input-selection__indicator {
  padding: 0 4px;
  color: var(--color-light-salmon);
}

.input-selection-container .input-selection__menu {
  border-radius: unset;
}

.input-selection-container .input-selection__option {
  background-color: var(--color-white);
}

.input-selection-container .input-selection__option:hover {
  background-color: var(--color-light-salmon-lighter);
}

.input-selection-container .input-selection__option--is-filtered,
.input-selection-container .input-selection__option--is-filtered:hover {
  background-color: var(--color-light-salmon);
}

@media (max-width: 1024px) {
  .input-selection-container {
    font-size: 10px;
  }

  .input-selection-container:focus {
    font-size: 10px;
  }

  .input-selection-container .input-selection__control {
    font-size: 10px;
  }
}

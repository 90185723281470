.container {
  padding-bottom: 40px;
}

.containerHeader {
  height: 60px;
  display: flex;
  justify-content: center;
}

.container .containerHeader > div:first-of-type {
  width: 95%;
  max-width: 950px;
  justify-content: flex-start;
  align-items: center;
}

.container .containerHeader h1 {
  font-size: var(--font-size-page-header);
}

.container .blocks {
  width: 95%;
  max-width: 950px;
}

.block > div {
  background-color: var(--color-white);
  padding: 20px;
  min-width: 290px;
}

.block h2 {
  color: var(--color-outer-space);
}

.search {
  display: flex;
}

.search .input {
  width: 40%;
  margin-right: auto;
}

.search input {
  text-transform: uppercase;
  height: 40px;
}

.search p {
  font-family: var(--font-family-title);
  font-size: var(--font-size-h2);
  margin: auto 15px auto auto;
}

.loading {
  margin: 50px 0 30px;
}

.notFound {
  margin: 50px 0 40px;
  text-align: center;
  font-family: var(--font-family-title);
}

.vouchers .header {
  padding: 0 20px;
  display: flex;
  margin-top: 15px;
  font-family: var(--font-family-title);
  border-bottom: 1px solid var(--color-davys-grey);
}

.vouchers .header .code {
  width: 52%;
  text-align: left;
}

.vouchers .header > p {
  width: 16%;
  text-align: center;
}

.vouchers .voucher {
  min-height: 70px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--color-dim-gray);
  font-family: var(--font-family-title);
}

.voucher > div {
  width: 16%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.voucher > .code {
  width: 52%;
  flex-direction: column;
  align-items: flex-start;
}

.voucher > .code h2,
.voucher > .code p {
  margin: 5px 0;
}

.voucher > .code h2 {
  text-transform: uppercase;
}

.voucher > .code h2:hover {
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-black);
}

.footer {
  margin-top: 30px;
  display: flex;
}

.limit {
  display: flex;
}

.footer p {
  font-family: var(--font-family-title);
  margin-left: 10px;
}

.footer .pages {
  margin-left: auto;
}

.container {
    padding-bottom: 40px;
}

.containerHeader {
    height: 60px;
    display: flex;
    justify-content: center;
}

.container .containerHeader > div:first-of-type {
    width: 95%;
    max-width: 950px;
    justify-content: flex-start;
    align-items: center;
}

.header {
    width: 100%;
}

.header h1 {
    font-size: var(--font-size-page-header);
}

.header h1 span {
    text-transform: uppercase;
}

.back {
    display: flex;
    align-items: center;
    margin: 0 auto 15px 0;
}

.back svg {
    height: 10px;
    width: 10px;
}

.back svg > path {
    fill: var(--color-international-orange-aerospace);
}

.back:hover svg > path {
    fill: var(--color-copper-red);
}

.container .blocks {
    width: 95%;
    max-width: 950px;
}

.block {
    margin-bottom: 20px;
}

.block > div {
    background-color: var(--color-white);
    padding: 20px;
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-title);
}

.block input {
    height: 35px;
}

.block h2 {
    margin-top: 15px;
}

.name h2 {
    margin-top: 10px;
}

.name .input {
    width: 100%;
}

.name {
    display: flex;
    flex-direction: row;
    width:100%;
}

.uploadBtn {
    display:flex;
    height:36px;
}

.interfaceButton {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.interfaceInfos {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    width: 30%;
}

.description h2 {
    margin-top: 10px;
}

.description .input {
    width: 100%;
}

.brand {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 80%;
}

.brandCols {
    width: 40%;
    display: flex;
    margin-right: 10px;
    flex-direction: column;
}

.brandCols h2 {
    margin-top: 10px;
}

.prodTime {
    width: 25%;
}

.itemInfos {
    display: flex;
    flex-direction: row;
}

.items {
    width: 33%;
    margin-right: 5px;
}

.brand  h2 {
    margin-top: 2px;
}

.codes {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
}

.sku  {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-right: 10px;
}

.sku h2 {
    margin-top: 10px;
}

.sku .input {
    width: 100%;
}

.value {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.valueItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
    margin-right: 10px;
    margin-top: 20px;
}

.value h2 {
    margin-top: 10px;
}


.price {
    display: flex;
    width: 80%;
    margin-right: 10px;
}

.value .input {
    margin-left: 10px;
    width: 100%;
}

.dimensions {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dimensions h1 {
    margin-top: 10px;
}

.dimensionRow {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.dimensionContainer {
    display: flex;
    flex-direction:column;
    width: 15%;
    height: 100%;
}

.dimensionContainerSelect {
    display: flex;
    flex-direction:column;
    margin-right: 20px;
    width: 20%;
    height: 100%;
}

.unitContainer {
    display: flex;
    flex-direction: row;
    max-height: 36px;
    width: 100%;
}

.dimensionInput {
    width: 80%;
}

.dimensionInputSelect {
    width:50%;
    margin-right: 10px;
}

.createProduct {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 30px;
    justify-content: space-around;
}

.createBtn {
    width: 40%;
}

.firstRow {
    display: flex;
    flex-direction: row;
}

.preview {
    display: flex;
    flex-direction:column;

}

.active {
    display: flex;
    flex-direction:column;
    width: 60%;
}

.productURL {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.productURLRow {
    margin-top: 10px;
    width:60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.urlTextInput {
    width: 85%;
}
.panelContainer {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}

.blocksWrap {
  flex: 1 1 auto;
  justify-content: center;
  display: flex;
  width: 100%;
}

.blocks {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: space-around;
}

.panelContainerClosedMenu {
  padding: 0px 15px 0px 10px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 980px) and (max-width: 1023px) {
  .panelContainer {
    padding: 0px 15px 0px 15px;
    margin-left: 170px;
  }

  .panelContainerClosedMenu {
    padding: 0px 15px 15px 15px;
  }
}

@media (min-width: 1024px) and (max-width: 1302px) {
  .panelContainer {
    padding: 0px 15px 0px 15px;
    margin-left: 210px;
  }
}

@media (min-width: 1303px) {
  .panelContainer {
    padding: 0px 15px 0px 15px;
    margin-left: 187px;
  }
}

.header {
  flex: 0 1 auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.header .title {
  width: 100%;
  display: flex;
  justify-content: center;
}

.header .title h1 {
  font-weight: normal;
  margin: 0;
}

.header .iconGoBack {
  position: absolute;
  width: 12px;
  height: auto;
  cursor: pointer;
  left: 5.56%;
}

.header .iconGoBack svg {
  align-self: center;
}

.header .logo {
  width: 65px;
  height: auto;
  align-self: center;
  margin: 0 auto;
}

@media  (max-width: 425px){
  .header {
    margin-top: 30px;
  }
}

@media (min-width: 426px) and  (max-width: 768px) {
  .header .title {
    margin-top: 40px;
  }
}
@media (min-width: 768px) and  (max-width: 1024px) {
  .header .title {
    margin-top: 40px;
  }
}

.container {
  padding-bottom: 40px;
}

.containerHeader {
  height: 60px;
  display: flex;
  justify-content: center;
}

.container .containerHeader > div:first-of-type {
  width: 95%;
  max-width: 950px;
  justify-content: flex-start;
  align-items: center;
}

.container .containerHeader h1 {
  font-size: var(--font-size-page-header);
}

.container .blocks {
  width: 95%;
  max-width: 950px;
}

.block > div {
  background-color: var(--color-white);
  padding: 20px;
  min-width: 290px;
}

.block h2 {
  color: var(--color-outer-space);
}

.search {
  display: flex;
}

.search .input {
  width: 40%;
  margin-right: auto;
}

.search input {
  height: 40px;
}

.loading {
  margin: 50px 0 30px;
}

.header {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-family: var(--font-family-title);
  border-bottom: 1px solid var(--color-davys-grey);
}

.header p {
  width: 20%;
  text-align: center;
}

.campaigns .campaign {
  display: flex;
  min-height: 70px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--color-dim-gray);
  font-family: var(--font-family-title);
}

.campaigns .campaign > div {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.campaign > div p {
  margin: 2px 0;
}

.campaign .name:hover {
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-black);
}

.campaign .voucher {
  text-transform: uppercase;
}

.notFound {
  margin: 50px 0 40px;
  text-align: center;
  font-family: var(--font-family-title);
}

.block .title {
  width: 100%;
  padding: 18px 20px 11px;
  background: var(--color-white);
  border-bottom: 1.5px solid var(--color-black);
}

.block.collapsible .title {
  cursor: pointer;
}

.block .title div {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.block .title div h1+h1 {
  margin-left: auto;
  font-weight: normal;
}

.block .title .upArrow {
  height: 8px;
  transition: transform 0.2s ease-out 0s;
}

.block .title .upArrow.down {
  transform: rotateZ(180deg);
}

.block .title h1,
.block .title p {
  margin: 0;
}

.block .content {
  width: 100%;
  background: var(--color-white);
}

.block.collapsible .content {
  transition: max-height 0.2s ease-out 0s;
  overflow-y: hidden;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .block .title {
    padding: 15px 30px 13px;
    border-bottom: 2px solid var(--color-black);
  }

  .block .collapsible .title {
    padding: 21px 19px;
  }

  .block .collapsible .title h1 {
    font-size: 18px;
  }
}

.container {
  padding-bottom: 40px;
}

.header {
  width: 100%;
}

.header h1 {
  font-size: var(--font-size-page-header);
}

.header h1 span {
  text-transform: uppercase;
}

.back {
  display: flex;
  align-items: center;
  margin: 0 auto 15px 0;
}

.back svg {
  height: 10px;
  width: 10px;
}

.back svg > path {
  fill: var(--color-international-orange-aerospace);
}

.back:hover svg > path {
  fill: var(--color-copper-red);
}

.container .blocks {
  width: 95%;
  max-width: 950px;
}

.block {
  margin-bottom: 20px;
}

.block > div {
  background-color: var(--color-white);
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-title);
}

.inputSecundary {
  height: 35px;
}

.block h2 {
  margin-top: 15px;
}

.rules,
.rules .value {
  display: flex;
}

.active h2 {
  margin-top: 0;
}

.code .input {
  width: 40%;
}

.code input {
  text-transform: uppercase;
}

.discount > div {
  display: flex;
  align-items: flex-start;
}

.discount .options {
  display: flex;
}

.options .choice {
  height: 37px;
  margin: 0 5px 0 0;
  padding: 7px 11px;
}

.rules > div {
  width: 30%;
}

.rules .dates {
  margin-left: 50px;
}

.rules .value {
  align-items: center;
}

.rules .value h2 {
  margin: 0 5px 0 0;
}

.rules .value .input {
  width: 100%;
}

.button {
  margin: 30px auto 0 auto;
  width: 40%;
}

.shipping {
  margin-top: 10px;
}
.shipping h2 {
  padding-bottom: 5px;
}

.shipping h3 {
  padding-top: 5px;
}

.shippmentContent {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 5px;
}

.shippmentContent div svg {
  margin-top: 7.1px;
}

.shippmentTitle {
  margin-top: 10px;
  margin-bottom: 5px;
  padding-bottom: 8px;
}

.modalMessage {
  font-size: 14px;
  font-weight: 400;
}

.container {
  padding-bottom: 40px;
}

.back {
  display: flex;
  align-items: center;
  margin: 0 auto 15px 0;
}

.back svg {
  height: 10px;
  width: 10px;
}

.back svg > path {
  fill: var(--color-international-orange-aerospace);
}

.back:hover svg > path {
  fill: var(--color-copper-red);
}

.container .blocks {
  width: 95%;
  max-width: 950px;
}

.block {
  margin-bottom: 20px;
}

.block > div {
  background-color: var(--color-white);
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: var(--font-family-title);
}

.block > div h2 {
  margin: 0 0 5px;
}

.block > div p,
.block > div a {
  font-size: var(--font-size-h2);
  margin: 5px 0;
}

.block > div a {
  text-transform: uppercase;
  font-weight: bold;
  color: var(--color-blue-crayola);
  text-decoration: unset;
}

.block.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.block.header > div {
  background: unset;
  padding: 0;
}

.block.header h1 {
  font-size: var(--font-size-page-header);
  margin: 0;
}

.block.header h2 {
  color: var(--color-dim-gray);
  margin: 0;
}

.block.header .status {
  margin-left: auto;
  padding: 10px;
  color: var(--color-white);
  text-transform: uppercase;
}

.block.header .status.green {
  background: var(--color-mantis);
}

.block.header .status.yellow {
  background: var(--color-orange-web);
}

.block.header .status.red {
  background: var(--color-tart-orange);
}

.block.header .status.blue {
  background: var(--color-blue-jeans);
}

.block.header .status.purple {
  background: var(--color-cadmium-violet);
}

.block a.phone {
  color: var(--color-jungle-green);
  font-weight: bold;
  display: flex;
  align-items: center;
  width: fit-content;
}

.phone svg {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  fill: var(--color-jungle-green);
}

.total {
  text-align: center;
}

.total h3 {
  font-size: var(--font-size-page-header);
}

.discounts .free {
  color: var(--color-jungle-green);
}

.address {
  max-width: 60%;
}

.delivery,
.invoice,
.discounts,
.tracking {
  text-align: right;
}

.delivery > div + div,
.payment > div + div {
  margin-top: 15px;
}

.products,
.products > div {
  width: 100%;
}

.products .formProducts {
  margin: 15px 0 20px;
  width: 60%;
}

.products .formProducts .product,
.contacts {
  display: flex;
  justify-content: space-between;
}

.history .list .item {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.history .list .item .marker {
  height: 12px;
  width: 12px;
  background-color: var(--color-light-salmon);
  margin: 0 15px 0 5px;
}

.transactional > div {
  display: flex;
}

.transactional .activity {
  margin: 15px 50px 0 0;
}

.transactional .activity .list {
  display: flex;
  margin: 15px 0;
}

.transactional .contact {
  position: relative;
  height: 15px;
  width: 15px;
  background-color: var(--color-silver);
  margin-right: 10px;
}

.transactional .contact.sent {
  background-color: var(--color-dark-salmon);
}

.transactional .contact .tooltip {
  visibility: hidden;
  width: 120px;
  background-color: var(--color-dim-gray);
  color: var(--color-white);
  text-align: center;
  padding: 5px 0;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.transactional .contact:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 600px) {
  .back {
    margin: 30px auto 15px 0;
  }

  .container .blocks {
    width: 100%;
  }

  .block > div {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  .block > div > div {
    margin: 10px 0;
  }

  .block.header > div {
    flex-direction: row;
  }

  .block.header > div > div {
    margin: 0;
  }

  .delivery,
  .invoice,
  .discounts,
  .tracking,
  .total {
    text-align: left;
  }

  .transactional > div {
    flex-direction: column;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal.active {
  display: flex;
}

.content {
  width: 100%;
  padding: 22px;
  margin: 30px;
  background-color: var(--color-white);
}

.text {
  margin: 0 0 17px;
  font-size: var(--font-size-h1);
}

.actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.actions p {
  margin: 0 10px;
  font-family: var(--font-family-title);
  font-size: var(--font-size-h2);
  font-weight: 600;
}

.actions p:first-child {
  margin-left: 0;
}

.actions p:last-child {
  margin-right: 0;
}

.actions p:hover {
  cursor: pointer;
}

.cancel {
  color: var(--color-international-orange-aerospace);
}

.accept {
  color: var(--color-light-salmon);
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .content {
    width: 100%;
    max-width: 440px;
    padding: 47px 32px 34px;
  }

  .text {
    margin: 0 0 30px;
  }
}

.container {
  padding-bottom: 40px;
}

.containerHeader {
  height: 60px;
  display: flex;
  justify-content: center;
}

.container .containerHeader > div:first-of-type {
  width: 95%;
  max-width: 950px;
  justify-content: flex-start;
  align-items: center;
}

.header {
  width: 100%;
}

.header h1 {
  font-size: var(--font-size-page-header);
}

.header h1 span {
  text-transform: uppercase;
}

.back {
  display: flex;
  align-items: center;
  margin: 0 auto 15px 0;
}

.back svg {
  height: 10px;
  width: 10px;
}

.back svg > path {
  fill: var(--color-international-orange-aerospace);
}

.back:hover svg > path {
  fill: var(--color-copper-red);
}

.container .blocks {
  width: 95%;
  max-width: 950px;
}

.block {
  margin-bottom: 20px;
}

.block > div {
  background-color: var(--color-white);
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-title);
}

.block input {
  height: 35px;
}

.block h2 {
  margin-top: 15px;
}

.closeheader {
  margin-bottom: 0;
}

.name h2 {
  margin-top: 0;
}

.sidebyside {
  display: flex;
}

.sidebyside > div:not(:first-child) {
  margin-left: 40px;
}

.productsHeader {
  display: flex;
}

.productsHeader > h3:last-child{
  margin-left: 170px;
}

.products > div:not(:last-child) .buttonAdd {
  display: None;
}

.product {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.product .input {
  margin-left: 20px;
  width: 13%;
}

.dates {
  margin-left: 80px;
}

.button {
  margin: 30px auto 0 auto;
  width: 40%;
}

.buttonAdd, .buttonSub {
  width: 37px;
  height: 37px;
  margin-left: 20px;
}

.hidden {
  display: none;
}

.filterContainer {
  width: 200px;
  height: 20px;
}
.container {
    padding-bottom: 40px;
}

.containerHeader {
    height: 60px;
    display: flex;
    justify-content: center;
}

.container .containerHeader > div:first-of-type {
    width: 95%;
    max-width: 950px;
    justify-content: flex-start;
    align-items: center;
}

.container .containerHeader h1 {
    font-size: var(--font-size-page-header);
}

.container .blocks {
    width: 95%;
    max-width: 950px;
}

.block > div {
    background-color: var(--color-white);
    padding: 20px;
    min-width: 290px;
    margin-top: 30px;
}

.block h2 {
    color: var(--color-outer-space);
}

.search {
    display: flex;
}

.search .input {
    width: 40%;
    margin-right: auto;
}

.search input {
    height: 40px;
}

.search p {
    font-family: var(--font-family-title);
    font-size: var(--font-size-h2);
    margin: auto 15px auto auto;
}

.productListContainer {
    display: flex;
    flex-direction: column;
}

.product {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    border-top: solid 1px var(--color-quick-silver);
}

.productName {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.productName h2:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--color-black);

}

.value {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15%;
}

.status{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
}

.valid {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 18%;
}

.benefit {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
}

.description {
    font-size: 11px;
    font-weight: 500;
    color: var(--color-dim-gray);
}

.description b {
    font-weight: 500;
    color: var(--color-black);
}

.product {
    width: 100%;
    padding: 0 10px 0 15px;
    border: solid 1px var(--color-silver);
}

.product .wrapper {
    display: flex;
    padding: 14px 0 8px;
}

.product .imageWrap {
    align-self: center;
}

.product .details {
    display: flex;
    justify-content: center;
    padding: 10px 5px 10px 10px;
    flex-direction: column;
}

.product .details .name {
    width: 100%;
    margin: 0;
    font-weight: 600;
    font-size: var(--font-size-h2);
}

.product .description {
    margin-top: 3px;
    font-family: var(--font-family-title);
    font-size: 10px;
    font-weight: 500;
}

.product .price {
    margin: 0;
    font-size: var(--font-size-h2);
    font-weight: 500;
}

.container {
  height: 100%;
  flex-flow: column;
  padding-bottom: 20px;
  min-height: 460px;
}

.container .header {
  margin: 20px 0 20px;
}

.header {
  margin: 0 0 20px;
}

.block,
.block > div {
  height: 100%;
}

.block > div {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.title {
  margin: 0;
  text-align: center;
}

.buttons > button {
  width: 100%;
  font-size: var(--font-size-button-primary);
  margin: 30px 0 13px;
}

.buttons > div {
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .container .header {
    margin: 20px 0 60px;
  }

  .container > .header > svg {
    width: 115px;
  }

  .block {
    display: flex;
    justify-content: center;
  }

  .block > div {
    max-width: 700px;
    padding: 40px 80px 30px;
    height: 75%;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buttons > button {
    max-width: 300px;
  }
}

.container {
  padding-bottom: 40px;
}

.containerHeader {
  height: 60px;
  display: flex;
  justify-content: center;
}

.container .containerHeader > div:first-of-type {
  width: 95%;
  max-width: 950px;
  justify-content: flex-start;
  align-items: center;
}

.header {
  width: 100%;
}

.header h1 {
  font-size: var(--font-size-page-header);
}

.header h1 span {
  text-transform: uppercase;
}

.back {
  display: flex;
  align-items: center;
  margin: 0 auto 15px 0;
}

.back svg {
  height: 10px;
  width: 10px;
}

.back svg > path {
  fill: var(--color-international-orange-aerospace);
}

.back:hover svg > path {
  fill: var(--color-copper-red);
}

.container .blocks {
  width: 95%;
  max-width: 950px;
}

.block {
  margin-bottom: 20px;
}

.block > div {
  background-color: var(--color-white);
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-title);
}

.block input {
  height: 35px;
}

.block h2 {
  margin-top: 15px;
}

.options {
  display: flex;
}

.options .choice:not(:first-child)  {
  margin-left: 10px;
}


.hintText {
  font-size: var(--text-size-help-popup);
}

.emails {
  min-width: 250px;
}

.emailButtons {
  margin-bottom: 7px;
  display: flex;
  width: 100%;
}

.uploadButton {
  margin-right: 10px;
  width: 120px;
  color: var(--color-white) !important;
  background: var(--color-light-salmon) !important;
}

.cleanButton {
  flex: 1;
}

.rawFilename {
  font-size: var(--font-size-input);
  margin: 0 0 3px 5px;
  font-weight: 600;
}

.emailsList {
  border: 1px solid var(--color-light-gray);
  padding: 5px 10px;
  width: 100%;
  height: 180px;
  overflow-y: auto;
}

.emailsList::-webkit-scrollbar {
  width: 15px;
}

.emailsList::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
.emailsList::-webkit-scrollbar-thumb {
  background: var(--color-light-salmon); 
}

.emailsList::-webkit-scrollbar-thumb:hover {
  background: var(--color-dark-salmon); 
}

.emailsList p {
  margin: 0;
}

.badEmail {
  color: red !important;
  
}

.noTopMargin {
  margin-top: 0 !important;
}

.closeheader {
  margin-bottom: 0;
}

.name h2 {
  margin-top: 0;
}

.name .input {
  width: 60%;
}

.quantity .input {
  width: 120px;
}

.modeContainer {
  max-width: 400px;
}

.flexContainer {
  display: flex;
}

.flexContainer > div:not(:first-child) {
  margin-left: 40px;
}

.sheet .input {
  width: 40%;
}

.productsHeader {
  display: flex;
}

.productsHeader > h3:not(:first-child){
  margin-left: 60px;
}

.product {
  display: flex;
  align-items: flex-start;
}

.product .input {
  margin-left: 20px;
  width: 20%;
}


.date {
  width: 200px;
}

.button {
  margin: 30px auto 0 auto;
  width: 40%;
}

.buttonAdd {
  width: 35px;
  height: 35px;
  margin-left: 20px;
}

.hidden {
  display: none;
}


@media (max-width: 600px) {
  .flexContainer {
    flex-direction: column;
  }

  .flexContainer > div:not(:first-child) {
    margin-left: 0;
  }

  .noTopMargin {
    margin-top: 15px !important;
  }
}

@media (max-width: 768px) {
  .containerHeader {
    height: auto;
  }

  .container .blocks {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .container .containerHeader > div:first-of-type {
    width: 100%;
    justify-content: center;
  }
}
.unauthorized {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.unauthorized h1 {
  font-size: var(--font-size-page-header);
  margin: 200px 0 40px;
}

.unauthorized svg {
  height: 100px;
  width: 100px;
}

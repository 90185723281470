.container {
  padding-bottom: 40px;
}

.header {
  width: 100%;
}

.header h1 {
  font-size: var(--font-size-page-header);
}

.header h1 span {
  text-transform: uppercase;
}

.back {
  display: flex;
  align-items: center;
  margin: 0 auto 15px 0;
}

.back svg {
  height: 10px;
  width: 10px;
}

.back svg > path {
  fill: var(--color-international-orange-aerospace);
}

.back:hover svg > path {
  fill: var(--color-copper-red);
}

.containerHeader {
  height: 60px;
  display: flex;
  justify-content: center;
}

.container .containerHeader > div:first-of-type {
  width: 95%;
  max-width: 950px;
  justify-content: flex-start;
  align-items: center;
}

.container .containerHeader h1 {
  font-size: var(--font-size-page-header);
}

.container .blocks {
  width: 95%;
  max-width: 950px;
}

.block > div {
  background-color: var(--color-white);
  padding: 20px;
  min-width: 290px;
}

.block h2 {
  color: var(--color-outer-space);
}

.search {
  display: flex;
}

.search .filter {
  width: 10%;
}

.search .input {
  margin: 0 10px;
  width: 40%;
}

.search p {
  font-family: var(--font-family-title);
  font-size: var(--font-size-h2);
  margin: auto 0 auto auto;
}

.search .input > div {
  height: 100%;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.filters > div {
  width: 16%;
}

.filters .input > div {
  height: 100%;
  max-height: 35px;
}

.metrics {
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0 10px;
  font-family: var(--font-family-title);
  font-size: var(--font-size-h2);
}

.metrics p {
  margin: 0;
}

.hashes .hash {
  width: 100%;
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid var(--color-dim-gray);
  font-family: var(--font-family-title);
  text-align: center;
}

.hashes .hash > div {
  width: 20%;
  display: flex;
  justify-content: center;
  padding: 0 10px;
}

.hashes .hash .number {
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.hashes .hash .number h2 {
  cursor: pointer;
}

.hashes .hash .number h2:hover {
  color: var(--color-black);
  text-decoration: underline;
}

.hash .number h2,
.hash .number p,
.hash .status p {
  margin: 0;
}

.hash .status > div {
  padding: 6px;
  text-transform: uppercase;
  font-size: var(--font-size-checkbox);
}

.hash .status.red > div {
  background: var(--color-tart-orange);
  color: var(--color-white);
}

.hash .status.green > div {
  background: var(--color-mantis);
  color: var(--color-white);
}

.hash .status .yellow > div {
  background: var(--color-orange-web);
  color: var(--color-white);
}

.hash .status .blue > div {
  background: var(--color-blue-jeans);
  color: var(--color-white);
}

.hash .status .purple > div {
  background: var(--color-cadmium-violet);
  color: var(--color-white);
}

.hashes .header {
  display: flex;
  margin-top: 15px;
  border-bottom: 1px solid var(--color-davys-grey);
}

.hashes .header p {
  width: 20%;
  text-align: center;
  font-family: var(--font-family-title);
}

.loading {
  margin: 50px 0 30px;
}

.notFound {
  margin: 50px 0 40px;
  text-align: center;
  font-family: var(--font-family-title);
}

.footer {
  margin-top: 30px;
  display: flex;
}

.limit {
  display: flex;
}

.footer p {
  font-family: var(--font-family-title);
  margin-left: 10px;
}

.footer .pages {
  margin-left: auto;
}

@media (max-width: 600px) {
  .search {
    flex-direction: column-reverse;
    align-items: center;
  }

  .search .filter,
  .search .input {
    margin: 5px 0;
    width: 100%;
    height: 40px;
  }

  .filters {
    flex-direction: column;
  }

  .filters + .filters {
    margin-top: 0;
  }

  .filters > div {
    width: 100%;
    margin: 5px 0;
  }

  .filters .input > div {
    height: 100%;
    height: 35px;
  }

  .hashes .header p {
    display: none;
  }

  .hashes .hash {
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0;
  }

  .hashes > .hash > div {
    width: 50%;
    padding: 3px 0;
  }

  .hashes > .hash > div p,
  .hashes > .hash > div h2 {
    margin: 0;
  }

  .footer,
  .limit {
    flex-direction: column;
  }

  .footer .pages,
  .footer .limit {
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .containerHeader {
    height: auto;
  }

  .container .blocks {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .container .containerHeader > div:first-of-type {
    width: 100%;
    justify-content: center;
  }
}

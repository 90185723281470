.container {
  padding-bottom: 40px;
}

.back {
  display: flex;
  align-items: center;
  margin: 0 auto 15px 0;
}

.back svg {
  height: 10px;
  width: 10px;
}

.back svg > path {
  fill: var(--color-international-orange-aerospace);
}

.back:hover svg > path {
  fill: var(--color-copper-red);
}

.container .blocks {
  width: 95%;
  max-width: 950px;
}

.block {
  margin-bottom: 20px;
}

.block > div {
  background-color: var(--color-white);
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: var(--font-family-title);
}

.block > div h2 {
  margin: 0 0 5px;
}

.block > div p,
.block > div a {
  font-size: var(--font-size-h2);
  margin: 5px 0;
}

.block > div a {
  text-transform: uppercase;
  font-weight: bold;
  color: var(--color-blue-crayola);
  text-decoration: unset;
}

.block.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.block.header > div {
  background: unset;
  padding: 0;
}

.block.header h1 {
  font-size: var(--font-size-page-header);
  margin: 0;
}

.block.header h2 {
  color: var(--color-dim-gray);
  margin: 0;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.client,
.total,
.discounts {
  max-width: 33%;
}

.tracking,
.delivery {
  max-width: 50%;
}

.total {
  text-align: center;
}

.total h3 {
  font-size: var(--font-size-page-header);
}

.discounts .free {
  color: var(--color-jungle-green);
}

.address {
  max-width: 60%;
}

.tracking,
.discounts {
  text-align: right;
}

.delivery > div + div {
  margin-top: 15px;
}

.products {
  margin-top: 15px;
}

.products,
.products > div {
  width: 100%;
}

.products .formProducts {
  margin: 15px 0 20px;
  width: 60%;
}

.products .formProducts .product,
.contacts {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .back {
    margin: 30px auto 15px 0;
  }

  .container .blocks {
    width: 100%;
  }

  .block > div {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  .block > div > div {
    margin: 10px 0;
  }

  .block.header > div {
    flex-direction: row;
  }

  .block.header > div > div {
    margin: 0;
  }

  .delivery,
  .invoice,
  .discounts,
  .tracking,
  .total {
    text-align: left;
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pagination a {
  min-width: 30px;
  padding: 10px 12px;
  margin: 0 2px;
  background: var(--color-white);
  font-family: var(--font-family-title);
}

.paginationLink {
  font-weight: bold;
}

.pagination svg {
  height: 15px;
  width: 15px;
}

.paginationLink.next svg {
  transform: rotate(180deg);
}

.paginationLinkActive a {
  color: var(--color-white);
  background: var(--color-light-salmon);
}
